{
  "name": "frontend-kirmes",
  "version": "18.114.0-RC.4",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "lint": "ng lint",
    "bundle-report": "webpack-bundle-analyzer dist/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.2",
    "@angular/cdk": "^17.1.2",
    "@angular/common": "^17.1.2",
    "@angular/core": "^17.1.2",
    "@angular/forms": "^17.1.3",
    "@angular/platform-browser": "^17.1.2",
    "@angular/platform-browser-dynamic": "^17.1.3",
    "@angular/router": "^17.1.3",
    "@codewithdan/observable-store": "^2.2.15",
    "@codewithdan/observable-store-extensions": "^2.2.9",
    "@microsoft/signalr": "^6.0.25",
    "@sentry/browser": "^6.19.7",
    "bootstrap": "^3.4.1",
    "core-js": "^3.35.1",
    "file-saver": "^2.0.5",
    "font-awesome": "^4.7.0",
    "keycloak-angular": "^15.1.0",
    "ngx-bootstrap": "^12.0.0",
    "ngx-progressbar": "^11.1.0",
    "ngx-toastr": "^16.2.0",
    "ngx-wallaby-jest": "0.0.2",
    "primeicons": "^6.0.1",
    "primeng": "^17.6.0",
    "rxjs": "^7.8.1",
    "ts-enum-util": "^4.0.2",
    "ts-md5": "^1.3.1",
    "tslib": "^2.0.0",
    "zone.js": "^0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.3",
    "@angular-eslint/builder": "^17.2.1",
    "@angular-eslint/eslint-plugin": "^17.2.1",
    "@angular-eslint/eslint-plugin-template": "^17.2.1",
    "@angular-eslint/schematics": "^17.2.1",
    "@angular-eslint/template-parser": "^17.2.1",
    "@angular/cli": "^17.1.3",
    "@angular/compiler": "^17.1.2",
    "@angular/compiler-cli": "^17.1.3",
    "@angular/language-service": "^17.1.3",
    "@types/node": "^16.18.80",
    "@typescript-eslint/eslint-plugin": "4.16.1",
    "@typescript-eslint/parser": "4.16.1",
    "eslint": "^7.32.0",
    "ts-node": "^9.1.1",
    "typescript": "~5.3.3",
    "webpack-bundle-analyzer": "^4.10.1"
  }
}
