<div class="container">
    <div class="row">
        <div class="col-md-12">
        @for (diffGroup of diffGroups; track diffGroup) {
            <ng-container *ngIf="diffGroup.diffs.length > 0">
                <h4>{{ diffGroup.name }}</h4>
                <p-table [value]="diffGroup.diffs" [autoLayout]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 33%"></th>
                            <th *ngIf="showOld" style="width: 33%">Alt</th>
                            <th style="width: 33%">{{ showOld ? 'Neu' : '&nbsp;' }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-diff>
                        <tr [pSelectableRow]="diff">
                            <td>{{diff.displayName}}</td>
                            <td *ngIf="showOld">{{diff.oldValue}}</td>
                            <td>{{diff.newValue}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-container>
        }
        </div>
    </div>
</div>
